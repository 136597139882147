import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {Asset, hierarchicalMenu, Link} from "../../plugins/gatsby-plugin-atollon";
import {colors} from "./primitives/colors";
import Container from "./primitives/grid/container";
import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import Logo from "../assets/images/logo.svg";
import StyledLink, {StyledLinkStyles} from "./primitives/styled-link";
import Row from "./primitives/grid/row";
import Col from "./primitives/grid/col";
import Typography from "./primitives/typography";
import {StyledButtonAsLink} from "./primitives/styled-button";
import DatoAsset from "../../plugins/gatsby-plugin-atollon/real-src/components/primitives/asset";
import VisuallyHidden from "./primitives/visually-hidden";
import {keyframes} from "@emotion/react";
import {breakpoints} from "./primitives/tokens";
import {useCompareContext} from "../contexts/compare-context";
import {useSearchContext} from "../contexts/search-context";
import {useStore} from "../contexts/store-context";

const enterFromRight = keyframes({
    from: { transform: 'translateX(200px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
});

const enterFromLeft = keyframes({
    from: { transform: 'translateX(-200px)', opacity: 0 },
    to: { transform: 'translateX(0)', opacity: 1 },
});

const exitToRight = keyframes({
    from: { transform: 'translateX(0)', opacity: 1 },
    to: { transform: 'translateX(200px)', opacity: 0 },
});

const exitToLeft = keyframes({
    from: { transform: 'translateX(0)', opacity: 1 },
    to: { transform: 'translateX(-200px)', opacity: 0 },
});

const fadeIn = keyframes({
    from: { opacity: 0 },
    to: { opacity: 1 },
});

const fadeOut = keyframes({
    from: { opacity: 1 },
    to: { opacity: 0 },
});
const DesktopHeader = () => {
    const {
        allDatoCmsHeaderMenuItem: {
            nodes: headerItems
        },
        datoCmsSiteOptionPage: {
            helpHeaderMenuLink
        }
    } = useStaticQuery(graphql`
        query HeaderItems {
            allDatoCmsHeaderMenuItem {
                nodes {
                    id
                    title
                    link {
                        ...LinkWithoutTitleProps
                    }
                    description
                    position
                    treeParent {
                        id
                    }
                    inlineLink {
                        ...LinkWithTitleProps
                    }
                    icon {
                        ...InternalAssetProps
                    }
                    image {
                        ...InternalAssetProps
                    }
                }
            }
            datoCmsSiteOptionPage {
                helpHeaderMenuLink {
                    ...LinkWithTitleProps
                }
            }
        }
    `)
    const menuItems = hierarchicalMenu(headerItems);

    const { items, isOpen, setIsOpen } = useCompareContext()
    const {
        isOpen: searchIsOpen,
        setIsOpen: setSearchIsOpen
    } = useSearchContext();

    const {
        cart
    } = useStore()

    return (
        <NavigationMenu.Root css={{
            position: 'relative',
            zIndex: 1,
            width: '100%',
            [breakpoints.headerBreakpoint]: {
                display: 'none'
            }
        }} delayDuration={100}>
            <div css={{
                backgroundColor: colors.shadesWhite,
                borderBottom: `1px solid ${colors.secondaryBeige3}`,
                '.white-logo &': {
                    backgroundColor: 'transparent',
                    borderBottom: `1px solid transparent`
                },
            }}>
                <Container css={{
                    '.white-logo.no-menu &': {
                        width: '80vw!important',
                        maxWidth: '80vw!important',
                        paddingLeft: '8px!important',
                        paddingRight: '8px!important',
                        [breakpoints.tb]: {
                            width: '100%!important',
                            maxWidth: '100%!important',
                            paddingLeft: '28px!important',
                            paddingRight: '28px!important',
                        }
                    }
                }}>
                    <NavigationMenu.List css={{
                        textAlign: 'center',
                        display: 'flex',
                        listStyleType: 'none',
                        justifyItems: 'space-between',
                        alignItems: 'stretch',
                        gap: '8px'
                    }}>
                        <NavigationMenu.Item css={{
                            width: '100%',
                            maxWidth: '134px',
                            position: 'relative',
                            zIndex: 10,
                            marginRight: '15px',
                        }}>
                            <div css={{
                                padding: '11px 0',
                                svg: {
                                    transition: '0.3s',
                                },
                                color: colors.primaryOrange,
                                '.white-logo &': {
                                    color: colors.shadesWhite
                                }
                            }}>
                                <NavigationMenu.NavigationMenuLink asChild>
                                <Link to="/">
                                    <Logo />
                                </Link>
                                </NavigationMenu.NavigationMenuLink>
                            </div>
                        </NavigationMenu.Item>
                        {menuItems.childMenu.map((item, idx) => {
                            if (item.childMenu.length > 0) {
                                return (
                                    <NavigationMenu.Item key={item.id}>
                                        <NavigationMenu.Trigger css={{
                                            display: 'flex',
                                            select: 'none',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            height: '100%',
                                            padding: '0 15px',
                                            transition: '0.2s',
                                            '.no-menu &': {
                                                opacity: '0',
                                                pointerEvents: 'none'
                                            }
                                        }} role="group">
                                            {item?.link ? (
                                                <NavigationMenu.NavigationMenuLink asChild>
                                                    <span>
                                                        <Link {...item.link} css={{
                                                            ...StyledLinkStyles,
                                                            '.white-logo &': {
                                                                color: colors.shadesWhite,
                                                            }
                                                        }}>
                                                            {item.title}
                                                        </Link>
                                                     </span>
                                                </NavigationMenu.NavigationMenuLink>
                                            ) : (
                                                <div css={{
                                                ...StyledLinkStyles,
                                                '.white-logo &': {
                                                color: colors.shadesWhite,
                                            }
                                            }}>
                                                {item.title}
                                                </div>
                                            )}
                                        </NavigationMenu.Trigger>
                                        <NavigationMenu.Content css={{
                                            '&[data-motion="from-start"]': { animationName: enterFromLeft },
                                            '&[data-motion="from-end"]': { animationName: enterFromRight },
                                            '&[data-motion="to-start"]': { animationName: exitToLeft },
                                            '&[data-motion="to-end"]': { animationName: exitToRight },
                                            animationDuration: '250ms',
                                            animationTimingFunction: 'ease',
                                            position: 'absolute',
                                            top: '0',
                                            left: '0',
                                            width: '100%',
                                        }}>
                                            <div css={{
                                                margin: 0,
                                                display: 'grid',
                                                width: '100%',
                                            }}>
                                                <Container css={{
                                                    padding: '48px 0'
                                                }}>
                                                    <NavigationMenu.Sub defaultValue={item.childMenu?.[0]?.id}>
                                                        <Row>
                                                            <Col breakpoints={{
                                                                dt: { span: 3 }
                                                            }}>
                                                                <Typography fontSize={{
                                                                    dt: 'h3'
                                                                }}>
                                                                    {item.title}
                                                                </Typography>
                                                                {item.description && (
                                                                    <Typography fontSize={{
                                                                        dt: 'p1'
                                                                    }} css={{
                                                                        marginTop: '16px'
                                                                    }}>
                                                                        {item.description}
                                                                    </Typography>
                                                                )}
                                                                {(item.inlineLink && item?.inlineLink?.length > 0) && (
                                                                    <div css={{
                                                                        marginTop: '44px'
                                                                    }}>
                                                                        <NavigationMenu.NavigationMenuLink asChild>
                                                                            <StyledButtonAsLink className="outlined-orange" {...item.inlineLink} />
                                                                        </NavigationMenu.NavigationMenuLink>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                            <Col breakpoints={{
                                                                dt: { span: 3 }
                                                            }}>
                                                                {item.childMenu && item.childMenu.length && (
                                                                    <NavigationMenu.List css={{
                                                                        listStyleType: 'none',
                                                                        maxWidth: '256px',
                                                                        width: '100%'
                                                                    }}>
                                                                        {item.childMenu.map((item) => (
                                                                            <NavigationMenu.Item value={item.id} key={item.id}>
                                                                                <NavigationMenu.Trigger css={{
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <NavigationMenu.NavigationMenuLink asChild>
                                                                                        <span>
                                                                                            <Link {...item.link} css={{
                                                                                                display: 'flex',
                                                                                                alignItems: 'center',
                                                                                                justifyContent: 'space-between',
                                                                                                width: '100%',
                                                                                                padding: '8px 0',
                                                                                                borderRadius: '0px',
                                                                                                transition: 'background-color 0.5s, padding 0.5s, border-radius 0.3s',
                                                                                                '.arrow-item': {
                                                                                                    transition: 'transform 0.3s, opacity 0.3s',
                                                                                                    transform: 'scale(1)',
                                                                                                    transformOrigin: 'right center',
                                                                                                },
                                                                                                '&:hover, [data-radix-collection-item][data-state="open"] &': {
                                                                                                    backgroundColor: colors.primaryOrange,
                                                                                                    padding: '8px 20px',
                                                                                                    color: colors.shadesWhite,
                                                                                                    borderRadius: '30px',
                                                                                                    '.group-item': {
                                                                                                        //opacity: 0,
                                                                                                        'img': {
                                                                                                            filter: 'invert(1) brightness(100)',
                                                                                                        }
                                                                                                    },
                                                                                                    '.arrow-item': {
                                                                                                        transform: 'scale(0)',
                                                                                                        opacity: 0,
                                                                                                    }
                                                                                                }
                                                                                            }}>
                                                                                                <span css={{
                                                                                                    display: 'flex',
                                                                                                    alignItems: 'center',
                                                                                                    gap: '12px'
                                                                                                }} className="group-item">
                                                                                                     {item?.icon && (
                                                                                                         <Asset css={{
                                                                                                             width: '26px',
                                                                                                             height: '26px',
                                                                                                         }} asset={item.icon} />
                                                                                                     )}
                                                                                                    {item.title}
                                                                                                </span>
                                                                                                <svg className="arrow-item" width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M12.0645 21.6412C12.0645 21.6412 16.9395 14.9036 21.6351 13.0156H4.23633M12.0645 4.39258C12.0645 4.39258 16.9395 11.1306 21.6351 13.0182H4.23633" stroke="currentColor" strokeLinejoin="bevel"/>
                                                                                                </svg>
                                                                                            </Link>
                                                                                        </span>
                                                                                    </NavigationMenu.NavigationMenuLink>
                                                                                </NavigationMenu.Trigger>
                                                                                <NavigationMenu.Content css={{
                                                                                    '&[data-motion="from-start"]': { animationName: fadeIn },
                                                                                    '&[data-motion="from-end"]': { animationName: fadeIn },
                                                                                    '&[data-motion="to-start"]': { animationName: fadeOut },
                                                                                    '&[data-motion="to-end"]': { animationName: fadeOut },
                                                                                    animationDuration: '250ms',
                                                                                    animationTimingFunction: 'ease',
                                                                                    position: 'absolute',
                                                                                    top: '0',
                                                                                    left: '0',
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <div css={{
                                                                                        display: 'flex',
                                                                                        justifyContent: 'space-between'
                                                                                    }}>
                                                                                        <div css={{
                                                                                            marginRight: '52px',
                                                                                            display: 'flex',
                                                                                            flexDirection: 'column',
                                                                                            gap: '10px'
                                                                                        }}>
                                                                                            {(item.childMenu && item.childMenu.length > 0) && item.childMenu.map((subItem) => (
                                                                                                <NavigationMenu.NavigationMenuLink asChild>
                                                                                                    <Typography as={Link} {...subItem.link} fontSize="p1" color="neutral10">
                                                                                                        {subItem.title}
                                                                                                    </Typography>
                                                                                                </NavigationMenu.NavigationMenuLink>
                                                                                            ))}
                                                                                        </div>
                                                                                        <div css={{
                                                                                            width: 'calc(70% - 52px)'
                                                                                        }}>
                                                                                            <DatoAsset asset={item.image} />
                                                                                        </div>
                                                                                    </div>
                                                                                </NavigationMenu.Content>
                                                                            </NavigationMenu.Item>
                                                                        ))}
                                                                    </NavigationMenu.List>
                                                                )}
                                                            </Col>
                                                            <Col breakpoints={{
                                                                dt: { span: 6 }
                                                            }} css={{
                                                                position: 'relative',
                                                            }}>
                                                                <NavigationMenu.Viewport css={{
                                                                    height: 'var(--radix-navigation-menu-viewport-height)'
                                                                }} />
                                                            </Col>
                                                        </Row>
                                                    </NavigationMenu.Sub>
                                                </Container>
                                            </div>
                                        </NavigationMenu.Content>
                                    </NavigationMenu.Item>
                                )
                            }
                            return (
                                <NavigationMenu.Item key={item.id}>
                                    <div css={{
                                        height: '100%',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: '0 15px',
                                        '.no-menu &': {
                                            opacity: '0',
                                            pointerEvents: 'none'
                                        },
                                        '.white-logo &': {
                                            color: colors.shadesWhite,
                                        }
                                    }}>
                                        <StyledLink {...item.link} css={{

                                        }}>
                                            {item.title}
                                        </StyledLink>
                                    </div>
                                </NavigationMenu.Item>
                            )
                        })}
                        <div css={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            gap: '16px',
                            '.no-menu &': {
                                opacity: 0,
                                pointerEvents: 'none'
                            }
                        }}>
                            <StyledButtonAsLink css={{
                                height: '31px',
                                padding: '3.5px 15px'
                            }} className="outlined-orange dt-p2 medium" {...helpHeaderMenuLink} />
                            <div css={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'flex-end',
                                gap: '2px'
                            }}>
                                <button type="button" onClick={() => {
                                    if (!searchIsOpen) {
                                        setSearchIsOpen(true)
                                    }
                                }} css={{
                                    borderRadius: '500px',
                                    width: '32px',
                                    height: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    transition: 'background-color 0.2s',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        backgroundColor: colors.neutral1
                                    }
                                }}>
                                    <VisuallyHidden>Search</VisuallyHidden>
                                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19 18.5L13 12.5M1 7.5C1 8.41925 1.18106 9.32951 1.53284 10.1788C1.88463 11.0281 2.40024 11.7997 3.05025 12.4497C3.70026 13.0998 4.47194 13.6154 5.32122 13.9672C6.1705 14.3189 7.08075 14.5 8 14.5C8.91925 14.5 9.82951 14.3189 10.6788 13.9672C11.5281 13.6154 12.2997 13.0998 12.9497 12.4497C13.5998 11.7997 14.1154 11.0281 14.4672 10.1788C14.8189 9.32951 15 8.41925 15 7.5C15 6.58075 14.8189 5.6705 14.4672 4.82122C14.1154 3.97194 13.5998 3.20026 12.9497 2.55025C12.2997 1.90024 11.5281 1.38463 10.6788 1.03284C9.82951 0.68106 8.91925 0.5 8 0.5C7.08075 0.5 6.1705 0.68106 5.32122 1.03284C4.47194 1.38463 3.70026 1.90024 3.05025 2.55025C2.40024 3.20026 1.88463 3.97194 1.53284 4.82122C1.18106 5.6705 1 6.58075 1 7.5Z" stroke="currentColor" strokeWidth="0.9" strokeLinecap="square" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                {items.length > 0 && (
                                    <button css={{
                                        borderRadius: '500px',
                                        width: '32px',
                                        height: '32px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        transition: 'background-color 0.2s',
                                        justifyContent: 'center',
                                        '&:hover': {
                                            backgroundColor: colors.neutral1
                                        }
                                    }} type="button" onClick={() => {
                                       if (!isOpen) {
                                           setIsOpen(true)
                                       }
                                    }}>
                                        <VisuallyHidden>Saved</VisuallyHidden>
                                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.42871 1.92871V19.0716L9.99524 14.2826L18.5716 19.0716V1.92871H1.42871Z" stroke="currentColor" strokeWidth="0.9" strokeMiterlimit="10"/>
                                        </svg>
                                    </button>
                                )}
                                {/*<button type="button">
                                    <VisuallyHidden>Account</VisuallyHidden>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_4002_7981)">
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeWidth="0.9"/>
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <circle cx="9.9644" cy="5.23686" r="4.28686" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                            <path d="M0.939258 15.7632C0.939258 13.3957 2.85855 11.4764 5.22612 11.4764H15.2262C17.5937 11.4764 19.513 13.3957 19.513 15.7632V20.0501H0.939258V15.7632Z" stroke="black" strokeOpacity="0.2" strokeWidth="0.9"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_4002_7981">
                                                <rect width="20" height="20" fill="white" transform="translate(0 0.5)"/>
                                            </clipPath>
                                        </defs>
                                    </svg>
                                </button>*/}
                                <Link css={{
                                    borderRadius: '500px',
                                    width: '32px',
                                    height: '32px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    transition: 'background-color 0.2s',
                                    justifyContent: 'center',
                                    position: 'relative',
                                    '&:hover': {
                                        backgroundColor: colors.neutral1
                                    },
                                }} to="/cart" type="button">
                                    <VisuallyHidden>Cart</VisuallyHidden>
                                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <mask id="path-1-inside-1_4002_7984" fill="white">
                                            <path d="M0.710205 6.21387H19.2502V19.3974H0.710205V6.21387Z"/>
                                        </mask>
                                        <path d="M0.710205 6.21387V5.31387H-0.189795V6.21387H0.710205ZM19.2502 6.21387H20.1502V5.31387H19.2502V6.21387ZM19.2502 19.3974V20.2974H20.1502V19.3974H19.2502ZM0.710205 19.3974H-0.189795V20.2974H0.710205V19.3974ZM0.710205 7.11387H19.2502V5.31387H0.710205V7.11387ZM18.3502 6.21387V19.3974H20.1502V6.21387H18.3502ZM19.2502 18.4974H0.710205V20.2974H19.2502V18.4974ZM1.61021 19.3974V6.21387H-0.189795V19.3974H1.61021Z" fill="currentColor" mask="url(#path-1-inside-1_4002_7984)"/>
                                        <path d="M14.2853 6.21442C14.2853 3.84749 12.3665 1.92871 9.99958 1.92871C7.63265 1.92871 5.71387 3.84749 5.71387 6.21442" stroke="currentColor" strokeWidth="0.9"/>
                                    </svg>
                                    {cart?.lines?.length > 0 && (
                                        <div css={{
                                            position: 'absolute',
                                            width: '100%',
                                            height: '100%',
                                            pointerEvents: 'none',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            top: '3px',
                                            fontSize: '10px'
                                        }}>
                                            {cart?.lines?.length}
                                        </div>
                                    )}
                                </Link>
                            </div>
                        </div>
                        <NavigationMenu.Indicator css={{
                            zIndex: -1,
                            '&[data-state="open"]': { animation: `${fadeIn} 400ms ease` },
                            '&[data-state="closed"]': { animation: `${fadeOut} 300ms ease` },
                            top: '100%',
                            transition: 'width transform 250ms ease',
                            transform: 'none!important'
                        }}>
                            <div css={{
                                position: 'fixed',
                                width: '100vw',
                                height: '100vh',
                                backgroundColor: colors.shadesBlack,
                                opacity: 0.5,
                                top: 0,
                                left: 0,
                            }} />
                        </NavigationMenu.Indicator>
                    </NavigationMenu.List>
                </Container>
            </div>
            <div css={{
                perspective: '2000px',
                position: 'absolute',
                top: '100%',
                left: 0,
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
            }}>
                <NavigationMenu.Viewport css={{
                    backgroundColor: 'white',
                    '&[data-state="open"]': { animation: `${fadeIn} 400ms ease` },
                    '&[data-state="closed"]': { animation: `${fadeOut} 300ms ease` },
                    position: 'relative',
                    height: 'var(--radix-navigation-menu-viewport-height)',
                    transformOrigin: 'top center',
                    overflow: 'hidden',
                    transition: 'width height 500ms',
                    width: '100%'
                }} />
            </div>
        </NavigationMenu.Root>
    )
}

export default DesktopHeader
